angular.module('app.controllers').controller('TraderListingCtrl', ['$scope', '$rootScope', '$timeout', '$cookies', '$location'].append(function(self, root, $timeout, $cookies, $location) {
  if (!root.beta) {
    return $location.path("/");
  }
}));

angular.module('app.controllers').controller('TraderSingleListingCtrl', ['$scope', '$rootScope', '$timeout', '$cookies', '$location', 'APIExhibitor'].append(function(self, root, $timeout, $cookies, $location, APIExhibitor) {
  var ref;
  self.id = self.get('id');
  if (!(root.beta || !self.id)) {
    return $location.path("/");
  }
  self.isLoading = true;
  APIExhibitor.get({
    id: self.id,
    role: 'trader',
    _track: ((ref = root.user) != null ? ref.shortcode : void 0) || 'test'
  }, function(data) {
    self.trader = data;
    self.isLoading = false;
    return console.log(data);
  }, function(err) {
    console.log(err);
    return self.isLoading = false;
  });
  self.openContactModal = function(type = 'contact') {
    self.contactModalEl = document.getElementById('contactModal');
    self.contactModal = bootstrap.Modal.getOrCreateInstance(self.contactModalEl);
    if (!type) {
      return;
    }
    if (!self.checkProfile()) {
      return root.profileModal.show();
    }
    self.modalType = type;
    self.resetContactData(self.modalType);
    self.sendContactSuccess = false;
    self.sendContactProccess = false;
    self.editProfile = false;
    self.formProccess = false;
    return self.contactModal.show();
  };
  return self.resetContactData = function(type = 'contact') {
    var contact, message;
    self.contactData = {
      name: '',
      note: ''
    };
    message = {
      note: ''
    };
    contact = {
      phone: '',
      email: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      company: '',
      position: ''
    };
    if (type === 'contact') {
      angular.extend(self.contactData, contact);
    }
    if (type === 'message') {
      angular.extend(self.contactData, message);
    }
    self.sendContactProccess = false;
    return self.sendContactErrors = null;
  };
}));
