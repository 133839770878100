angular.module('app.controllers').controller 'TraderListingCtrl', [
  '$scope', '$rootScope', '$timeout', '$cookies', '$location'
].append (self, root, $timeout, $cookies, $location) ->
  return $location.path("/") unless root.beta

angular.module('app.controllers').controller 'TraderSingleListingCtrl', [
  '$scope', '$rootScope', '$timeout', '$cookies', '$location', 'APIExhibitor'
].append (self, root, $timeout, $cookies, $location, APIExhibitor) ->
  self.id = self.get('id')
  return $location.path("/") unless root.beta || !self.id


  self.isLoading = true
  APIExhibitor.get {id: self.id, role: 'trader', _track: root.user?.shortcode || 'test'}, (data) ->
    self.trader = data
    self.isLoading = false
    console.log(data)
  , (err) ->
    console.log(err)
    self.isLoading = false


  self.openContactModal = (type = 'contact') ->
    self.contactModalEl = document.getElementById('contactModal')
    self.contactModal   = bootstrap.Modal.getOrCreateInstance(self.contactModalEl)

    return unless type
    return root.profileModal.show() unless self.checkProfile()

    self.modalType = type
    self.resetContactData(self.modalType)
    self.sendContactSuccess   = false
    self.sendContactProccess  = false
    self.editProfile  = false
    self.formProccess = false
    self.contactModal.show()

  self.resetContactData = (type = 'contact') ->
    self.contactData =
      name: ''
      note: ''

    message =
      note: ''

    contact =
      phone: ''
      email: ''
      address: ''
      zip: ''
      city: ''
      country: ''
      company: ''
      position: ''

    angular.extend(self.contactData, contact) if type == 'contact'

    angular.extend(self.contactData, message) if type == 'message'

    self.sendContactProccess  = false
    self.sendContactErrors    = null



